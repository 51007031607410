jQuery(document).ready(function($){

// Slick slider on the front page - Categories

	$('.container-categories .category-slides').slick({ 
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
				variableWidth: true,
        dots: false,
        arrows: true,
        mobileFirst: true,
        nextArrow: '<button type="button" class="slick-next"><span class="fa fa-2x fa-chevron-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-2x fa-chevron-left"></button>',
        responsive: [
       		{
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            }
          },
       		{
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
  });

// Slick slider on the front page - Clients

	$('.container-clients .client-slides').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        mobileFirst: true,
        nextArrow: '<button type="button" class="slick-next"><span class="fa fa-2x fa-arrow-circle-o-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-2x fa-arrow-circle-o-left"></button>',
        responsive: [
					{
          	breakpoint: 612,
            settings: {
	            slidesToShow: 2,
						}
		     	},
     		 	{
            breakpoint: 767,
	          settings: {
      	      slidesToShow: 3,
  		      }
  		    },
     		 	{
            breakpoint: 991,
	          settings: {
      	      slidesToShow: 4,
  		      }
  		    }
        ]
  });

// Slick slider on the single product pages - Related products

	$('.related-products .products').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        mobileFirst: true,
        nextArrow: '<button type="button" class="slick-next"><span class="fa fa-2x fa-chevron-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-2x fa-chevron-left"></button>',
        responsive: [
					{
          	breakpoint: 659,
            settings: {
	            slidesToShow: 2,
						}
		     	},
     		 	{
            breakpoint: 991,
	          settings: {
      	      slidesToShow: 3,
  		      }
  		    },
     		 	{
            breakpoint: 1199,
	          settings: {
      	      slidesToShow: 4,
  		      }
  		    }
        ]
  });

// Slick slider on the single product pages - Product thumbnails

	$('.product-thumbnails').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        mobileFirst: true,
        nextArrow: '<button type="button" class="slick-next"><span class="fa fa-2x fa-chevron-right"></span></button>',
        prevArrow: '<button type="button" class="slick-prev"><span class="fa fa-2x fa-chevron-left"></button>',
        responsive: [
					{
          	breakpoint: 399,
            settings: {
	            slidesToShow: 2,
						}
		     	},
     		 	{
            breakpoint: 549,
	          settings: {
      	      slidesToShow: 3,
  		      }
  		    },
     		 	{
            breakpoint: 699,
	          settings: {
      	      slidesToShow: 4,
  		      }
  		    },
     		 	{
            breakpoint: 749,
	          settings: {
      	      slidesToShow: 5,
  		      }
  		    },
     		 	{
            breakpoint: 991,
	          settings: {
      	      slidesToShow: 3,
  		      }
  		    },
     		 	{
            breakpoint: 1199,
	          settings: {
      	      slidesToShow: 4,
  		      }
  		    }
        ]
  });

// Tabs on the single product page - Tabs

	$('.single-product-tabs .nav-tabs li a').click(function (e) {
	  e.preventDefault()
	  $(this).tab('show')
	})

// Accordion display

	function toggleChevron(e) {
	    $(e.target)
	        .prev('.panel-heading')
	        .find("i.indicator")
	        .toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
	}

	$('#accordion').on('hidden.bs.collapse', toggleChevron);
	$('#accordion').on('shown.bs.collapse', toggleChevron);

// Collapse accordians when width < ???

  function processBodies(width) {
  	if ($(window).width() < width) {
	    if ($(this).hasClass('in')) {
	        $(this).collapse('toggle');
	    }
		}
  }

  $('.accordion-collapse-xs .panel-collapse').each(function(){
	  processBodies.call(this, 768);
  })

  $('.accordion-collapse-sm .panel-collapse').each(function(){
	  processBodies.call(this, 991);
  })

// Home page video reveal

	if (typeof Wistia != "undefined") {

	  wistiaEmbed = Wistia.embed("fslj5wc5q0", {
		    videoFoam: true,
        playButton: false, 
        controlsVisibleOnLoad: false,
	  });

		function showVideo(e) {
			$(".media .overlay").fadeOut(250,function(){
				$(".media .video").delay(100).fadeTo(250,1);
				wistiaEmbed.play();
			});
		}

	  $('.media .overlay').on('click', showVideo);

	}

// Contact form 7 messages.

	$(".wpcf7").on('wpcf7:mailsent', function(event) {
		swal({title: "Message Sent", text: "Your message has been successfully sent.", type: "error", confirmButtonText: "Ok" });
	});

	$(".wpcf7").on('wpcf7:mailfailed', function(event) {
		swal({title: "Message Failed", text: "Your message failed to send. Our apologies for the inconvienience. Please email us for your requirements.", type: "error", confirmButtonText: "Ok" });
	});

	$(".wpcf7").on('wpcf7:invalid', function(event) {
		swal({title: "Validation Errors", text: "Validation errors occured. Please correct and re-submit.", type: "error", confirmButtonText: "Ok" });
	});

// Make sure select boxes are grey when nothing selected

	function colorizeSelect(){
	    if($(this)[0].selectedIndex == 0) $(this).addClass("empty");
	    else $(this).removeClass("empty")
	}

	$("#product-search select").on('change keyup', colorizeSelect).change();

// Sidebar Widget/Filter Collapsing

	function docollapse(event) {

		if (typeof event.data === 'undefined') {
			collapseTarget = 'div';
		} else {
			collapseTarget = event.data.collapseTarget;
		}

		$(this).parent().next(collapseTarget).slideToggle(250);																			// find the content and hide/show it
		$(this).find("i").toggleClass("glyphicon-chevron-down glyphicon-chevron-up");				// Toggle the chevron
	}

	$(".wcpf_master_wrap h3 span.archive-filter-header").on('click', docollapse);

	if ($(window).width() < 991) {

		$(".wcpf_master_wrap h3 span.archive-filter-header").each(docollapse)

		if ($(window).width() < 768) {

			$('wcpf_master_wrap .panel-collapse:not(".in")').collapse('show');

		}
	}

	$(".klood_widget_categories h3 span.archive-filter-header").on('click', { collapseTarget: "ul" }, docollapse);
	$(".klood_widget_archives   h3 span.archive-filter-header").on('click', { collapseTarget: "ul" }, docollapse);

// Retina Device Detection


  (function() {

    if ( 'devicePixelRatio' in window ) {

      var saved = readCookie('device_pixel_ratio');

      var doit = false;

      if (saved === null ) {
        doit = true;
      } else if ( saved != window.devicePixelRatio ) {
        doit = true;
      }

      if (doit) {

        createCookie('device_pixel_ratio',window.devicePixelRatio, 3600000);

        window.location.reload();
      }
    }

  })();

  function createCookie(name,value,expire) {

      if (expire) {
          var date = new Date();
          date.setTime(date.getTime()+ expire);
          var expires = "; expires="+date.toUTCString();
      }
      else var expires = "";
      document.cookie = name+"="+value+expires+"; path=/";

  }

  function readCookie(name) {

      var nameEQ = name + "=";
      var ca = document.cookie.split(';');

      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }

      return null;

  }

  function eraseCookie(name) {
      createCookie(name,"",-1);
  }

});

